import React from 'react';
import './IconBox.scss';

const IconBox = ({ icon, title }) => {
    return (
        <div className='iconBox'>
            <img className='iconBox__img' src={require(`../../assets/images/left_side/${icon}.png`).default} />
            <span className='iconBox__title'>{title}</span>
        </div>
    )
}

export default IconBox;