import React from 'react';
import './TextField.scss';

const TextField = ({ title }) => {
    return (
        <div className='textField'>
            <span className='textField__title'>{title}</span>
            <input className='textField__input' type="text" />
        </div>
    )
}

export default TextField;