import React from 'react';
import './SellerCard.scss';

const SellerCard = ({ time, imgUrl, walletAddress, quantity, price, currency }) => {
    return (
        <div className='sellerCard'>
            <span className='sellerCard__title'>{time}</span>
            <div className='sellerCard__img'><img src={require(`../../assets/images/sellers/${imgUrl}.png`).default} /></div>
            <span className='sellerCard__walletText'>{walletAddress}</span>
            <div className='sellerCard__quantityBox'>
                <span className='sellerCard__quantityBox__title1'>quantity</span>
                <span className='sellerCard__quantityBox__title2'>{quantity}</span>
            </div>
            <div className='sellerCard__quantityBox'>
                <span className='sellerCard__quantityBox__title1'>Price</span>
                <span className='sellerCard__quantityBox__title2'>{price}</span>
            </div>
            <div className='sellerCard__quantityBox'>
                <span className='sellerCard__quantityBox__title1'>Currency</span>
                <img src={require(`../../assets/images/sellers/${currency}.png`).default} />
            </div>
        </div>
    )
}

export default SellerCard;