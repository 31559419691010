import React from 'react';
import './DetailItem.scss';
import LeftSide from '../../components/LeftSide/LeftSide';
import SellerCard from '../../components/SellerCard/SellerCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const sellersList = [
    {
        time: '51 minutes ago',
        imgUrl: 'seller1',
        walletAddress: '7WP5...6qPY',
        quantity: 1,
        price: 695,
        currency: 'bnb'
    },
    {
        time: '3 days ago',
        imgUrl: 'seller2',
        walletAddress: '7WP5...6qPY',
        quantity: 1,
        price: 700,
        currency: 'bnb'
    },
    {
        time: '14 days ago',
        imgUrl: 'seller1',
        walletAddress: '7WP5...6qPY',
        quantity: 1,
        price: 508,
        currency: 'bnb'
    },
    {
        time: '7 minutes ago',
        imgUrl: 'seller2',
        walletAddress: '7WP5...6qPY',
        quantity: 1,
        price: 807,
        currency: 'bnb'
    },
    {
        time: '14 minutes ago',
        imgUrl: 'seller1',
        walletAddress: '7WP5...6qPY',
        quantity: 1,
        price: 871,
        currency: 'bnb'
    },
    {
        time: '45 days ago',
        imgUrl: 'seller2',
        walletAddress: '7WP5...6qPY',
        quantity: 1,
        price: 784,
        currency: 'bnb'
    },
    {
        time: '1 minutes ago',
        imgUrl: 'seller1',
        walletAddress: '7WP5...6qPY',
        quantity: 1,
        price: 987,
        currency: 'bnb'
    },
    {
        time: '7 minutes ago',
        imgUrl: 'seller2',
        walletAddress: '7WP5...6qPY',
        quantity: 1,
        price: 807,
        currency: 'bnb'
    },
    {
        time: '14 minutes ago',
        imgUrl: 'seller1',
        walletAddress: '7WP5...6qPY',
        quantity: 1,
        price: 871,
        currency: 'bnb'
    },
    {
        time: '45 days ago',
        imgUrl: 'seller2',
        walletAddress: '7WP5...6qPY',
        quantity: 1,
        price: 784,
        currency: 'bnb'
    },
    {
        time: '1 minutes ago',
        imgUrl: 'seller1',
        walletAddress: '7WP5...6qPY',
        quantity: 1,
        price: 987,
        currency: 'bnb'
    },
    {
        time: '7 minutes ago',
        imgUrl: 'seller2',
        walletAddress: '7WP5...6qPY',
        quantity: 1,
        price: 807,
        currency: 'bnb'
    },
    {
        time: '14 minutes ago',
        imgUrl: 'seller1',
        walletAddress: '7WP5...6qPY',
        quantity: 1,
        price: 871,
        currency: 'bnb'
    },
    {
        time: '45 days ago',
        imgUrl: 'seller2',
        walletAddress: '7WP5...6qPY',
        quantity: 1,
        price: 784,
        currency: 'bnb'
    },
    {
        time: '1 minutes ago',
        imgUrl: 'seller1',
        walletAddress: '7WP5...6qPY',
        quantity: 1,
        price: 987,
        currency: 'bnb'
    },
]

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    swipeToSlide: true,
    slidesToScroll: 5,
    initialSlide: 0,
    arrows: false,
    responsive: [
        {
            breakpoint: 2000,
            settings: {
              slidesToShow: 10,
              slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1600,
            settings: {
              slidesToShow: 8,
              slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1400,
            settings: {
              slidesToShow: 7,
              slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1300,
            settings: {
              slidesToShow: 8,
              slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1199,
            settings: {
              slidesToShow: 7,
              slidesToScroll: 1,
            }
        },
        {
            breakpoint: 991,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
            }
        },
        {
            breakpoint: 860,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            }
        },
        {
            breakpoint: 725,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            }
        },
        {
            breakpoint: 550,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
        },
        {
            breakpoint: 430,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
        }
    ]
  };


const DetailItem = () => {
    return (
        <div>
            <LeftSide />
            <div className='main-container'>
                <div className='detailItem'>
                    <div class="menu-icon dw dw-menu"></div>
                    <span className='title'>/ Character / Land of Earth / Goalkeeper</span>
                    <div className='detailItem__img'>
                        <img src={require('../../assets/images/detail/detail.png').default} />
                    </div>
                    <div className='itemBox'>
                        <div className='topBox'>
                            <img src={require('../../assets/images/detail/flag.png').default} />
                            <div className='topBox__right'>
                                <div className='topBox__right__firstRow'>
                                    <span className='topBox__right__firstRow__title1'>item name</span>
                                    <span className='topBox__right__firstRow__title2'>legendary</span>
                                </div>
                                <div className='topBox__right__secondRow'>
                                    <span className='topBox__right__secondRow__title'>
                                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages ....
                                    </span>
                                </div>
                                <div className='topBox__right__thirdRow'>
                                    <span className='topBox__right__thirdRow__createBtn'>create order</span>
                                    <div className='topBox__right__thirdRow__rightPanel'>
                                        <img src={require('../../assets/images/detail/bnb.png').default} />
                                        <div className='topBox__right__thirdRow__rightPanel__customSelect'>
                                            <select>
                                                <option>BNB</option>
                                                <option>FWC</option>
                                                <option>FWCL</option>
                                                <option>USDT</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='detailSkillBox'>
                        <div className='detailBox'>
                            <span className='detailBox__title'>details</span>
                            <table className="detailSkillBox__table">
                                <tr>
                                    <th className='th_text width-40'>food</th>
                                    <th className='th_text width-30'>energy</th>
                                    <th className='th_text width-30'>endurance</th>
                                </tr>
                                <tr>
                                    <td className='td_text width-40'>food name</td>
                                    <td className='td_text width-30'>100</td>
                                    <td className='td_text width-30'>48</td>
                                </tr>
                            </table>
                            <table className="detailSkillBox__table">
                                <tr>
                                    <th className='th_text width-40'>defence</th>
                                    <th className='th_text width-30'>power</th>
                                    <th className='th_text width-30'>speed</th>
                                </tr>
                                <tr>
                                    <td className='td_text width-40'>51</td>
                                    <td className='td_text width-30'>242</td>
                                    <td className='td_text width-30'>76</td>
                                </tr>
                            </table>
                            <table className="detailSkillBox__table">
                                <tr>
                                    <th className='th_text width-40'>Reaction</th>
                                    <th className='th_text width-30'>injured</th>
                                    <th className='th_text width-30'></th>
                                </tr>
                                <tr>
                                    <td className='td_text width-40'>7.5</td>
                                    <td className='td_text width-30'>6</td>
                                    <td className='td_text width-30'></td>
                                </tr>
                            </table>
                        </div>
                        <div className='skillBox'>
                            <span className='skillBox__title'>skills</span>
                            <table className="detailSkillBox__table">
                                <tr>
                                    <th className='th_text width-40'>food</th>
                                    <th className='th_text width-30'>energy</th>
                                    <th className='th_text width-30'></th>
                                </tr>
                                <tr>
                                    <td className='td_text width-40'>food name</td>
                                    <td className='td_text width-30'>100</td>
                                    <td className='td_text width-30'></td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div className='sellersPanel'>
                        <div className='sellersPanel__topBox'>
                            <span className='sellersPanel__topBox__title'>sellers</span>
                            <div className='sellersPanel__topBox__rightIconPanel'>
                                <img src={require('../../assets/images/home/left.png').default} />
                                <img src={require('../../assets/images/home/right.png').default} />
                            </div>
                        </div>
                        <div className='sellersPanel__sellerBox'>
                            <Slider {...settings}>
                                {sellersList.map((item, index) => (
                                    <div key={index}>
                                        <SellerCard 
                                            time={item.time} 
                                            imgUrl={item.imgUrl}
                                            walletAddress={item.walletAddress}
                                            quantity={item.quantity}
                                            price={item.price}
                                            currency={item.currency}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                    
                    <div className='sellersPanel'>
                        <div className='sellersPanel__topBox'>
                            <span className='sellersPanel__topBox__title'>sellers</span>
                            <div className='sellersPanel__topBox__rightIconPanel'>
                                <img src={require('../../assets/images/home/left.png').default} />
                                <img src={require('../../assets/images/home/right.png').default} />
                            </div>
                        </div>
                        <div className='sellersPanel__sellerBox'>
                            <Slider {...settings}>
                                {sellersList.map((item, index) => (
                                    <div key={index}>
                                        <SellerCard 
                                            time={item.time} 
                                            imgUrl={item.imgUrl}
                                            walletAddress={item.walletAddress}
                                            quantity={item.quantity}
                                            price={item.price}
                                            currency={item.currency}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailItem;