import React from 'react';
import './LeftButton.scss';

const LeftButton = ({ icon, title }) => {
    return (
        <div className='leftButton'>
            <img className='leftButton__img' src={require(`../../assets/images/left_side/${icon}.png`).default} />
            <span className='leftButton__label'>{title}</span>
        </div>
    )
}

export default LeftButton;