import React, {useEffect} from 'react';
import './MyOrders.scss';
import LeftSide from '../../components/LeftSide/LeftSide';
import DeleteBtn from '../../assets/images/delete_btn.png';
import OrderImg from '../../assets/images/orders/order1.png';

const MyOrders = () => {
    useEffect(() => {
        $('.myTable').DataTable({
            "paging": false,
            "ordering": true,
            "searching": false,
            "bDestroy": true
         });
    }, []);
    return (
        <div>
            <LeftSide />
            <div className='main-container'>
                <div className='myOrdersPanel'>
                    <div class="menu-icon dw dw-menu"></div>
                    <span className='title'>/ my orders</span>
                    <span className='titleHeader1'>my <span className='titleHeader2'>orders</span></span>
                    <div className='myOrdersPanel__tabs'>
                        <div className="tab">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active text-blue" data-toggle="tab" href="#home" role="tab" aria-selected="true">BUY</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-blue" data-toggle="tab" href="#profile" role="tab" aria-selected="false">SELL</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="home" role="tabpanel">
                                    <div className="card-box mt-30">
                                        <div className="" style={{  }}>
                                            <table className="data-table table stripe hover nowrap myTable">
                                                <thead>
                                                    <tr>
                                                        <th className="table-plus datatable-nosort">Item</th>
                                                        <th>Name</th>
                                                        <th>Quantity</th>
                                                        <th>Price</th>
                                                        <th>Currency</th>
                                                        <th>Time</th>
                                                        <th className="datatable-nosort">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="table-plus">
                                                            <img src={OrderImg} />
                                                        </td>
                                                        <td>GoalKeeper</td>
                                                        <td>1</td>
                                                        <td>0.1 </td>
                                                        <td>FWCL</td>
                                                        <td>42 seconds ago</td>
                                                        <td>
                                                            <img src={DeleteBtn} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-plus">
                                                            <img src={OrderImg} />
                                                        </td>
                                                        <td>GoalKeeper</td>
                                                        <td>1</td>
                                                        <td>0.1 </td>
                                                        <td>FWCL</td>
                                                        <td>42 seconds ago</td>
                                                        <td>
                                                            <img src={DeleteBtn} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-plus">
                                                            <img src={OrderImg} />
                                                        </td>
                                                        <td>GoalKeeper</td>
                                                        <td>1</td>
                                                        <td>0.1 </td>
                                                        <td>FWCL</td>
                                                        <td>42 seconds ago</td>
                                                        <td>
                                                            <img src={DeleteBtn} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-plus">
                                                            <img src={OrderImg} />
                                                        </td>
                                                        <td>GoalKeeper</td>
                                                        <td>1</td>
                                                        <td>0.1 </td>
                                                        <td>FWCL</td>
                                                        <td>42 seconds ago</td>
                                                        <td>
                                                            <img src={DeleteBtn} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="profile" role="tabpanel">
                                    <div className="card-box mt-30">
                                        <div className="" style={{  }}>
                                            <table className="data-table table stripe hover nowrap myTable">
                                                <thead>
                                                    <tr>
                                                        <th className="table-plus datatable-nosort">Item</th>
                                                        <th>Name</th>
                                                        <th>Quantity</th>
                                                        <th>Price</th>
                                                        <th>Currency</th>
                                                        <th>Time</th>
                                                        <th className="datatable-nosort">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="table-plus">
                                                            <img src={OrderImg} />
                                                        </td>
                                                        <td>GoalKeeper</td>
                                                        <td>1</td>
                                                        <td>0.1 </td>
                                                        <td>FWCL</td>
                                                        <td>42 seconds ago</td>
                                                        <td>
                                                            <img src={DeleteBtn} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-plus">
                                                            <img src={OrderImg} />
                                                        </td>
                                                        <td>GoalKeeper</td>
                                                        <td>1</td>
                                                        <td>0.1 </td>
                                                        <td>FWCL</td>
                                                        <td>42 seconds ago</td>
                                                        <td>
                                                            <img src={DeleteBtn} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-plus">
                                                            <img src={OrderImg} />
                                                        </td>
                                                        <td>GoalKeeper</td>
                                                        <td>1</td>
                                                        <td>0.1 </td>
                                                        <td>FWCL</td>
                                                        <td>42 seconds ago</td>
                                                        <td>
                                                            <img src={DeleteBtn} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="table-plus">
                                                            <img src={OrderImg} />
                                                        </td>
                                                        <td>GoalKeeper</td>
                                                        <td>1</td>
                                                        <td>0.1 </td>
                                                        <td>FWCL</td>
                                                        <td>42 seconds ago</td>
                                                        <td>
                                                            <img src={DeleteBtn} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyOrders;