import React from 'react';
import './CharacterCard.scss';

const ChracterCard = ({ imgUrl }) => {
    return (
        <div className='characterCard'>
            <div className='titleBox'>
                <span className='titleBox__title1'>RARITY</span>
                <span className='titleBox__title2'>LEGENDARY</span>
            </div>
            <div className='characterCard__imgPanel'>
                <img className='characterCard__imgPanel__img' src={require(`../../assets/images/characters/${imgUrl}.png`).default} />
            </div>
            <div className='viewItem'>
                <span className='viewItem__text'>VIEW ITEM</span>
            </div>
        </div>
    )
}

export default ChracterCard;