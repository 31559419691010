import React from 'react';
import './Description.scss'

const Description = ({ title, comment }) => {
    return (
        <div className='descriptionPanel'>
            <span className='descriptionPanel__title'>{title}</span>
            <span className='descriptionPanel__desc'>{comment}</span>
        </div>
    )
}

export default Description;