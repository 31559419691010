import React from 'react';
import './ListItems.scss';
import LeftSide from '../../components/LeftSide/LeftSide';
import ChracterCard from '../../components/CharacterCard/CharacterCard';

const images = [
    'character4',
    'character5',
    'character6',
    'character4',
    'character5',
    'character6',
    'character7',
    'character8',
    'character9',
]

const ListItems = () => {
    return (
        <div>
            <LeftSide />
            <div className='main-container'>
                <div className='listItems'>
                    <div class="menu-icon dw dw-menu"></div>
                    <span className='title'>/ Character/ Land of Earth</span>
                    <span className='titleHeader1'>Land of <span className='titleHeader2'>Angel</span></span>
                    <div className='cardBox'>
                        {images.map((item, index) => (
                            <div key={index} className='cardBox__panel'><ChracterCard imgUrl={item} /></div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListItems;