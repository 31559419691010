import React, {useEffect} from 'react';
import './Home.scss';
import LeftSide from '../../components/LeftSide/LeftSide';
import leftArrowIcon from '../../assets/images/home/left.png';
import rightArrowIcon from '../../assets/images/home/right.png';
import ChracterCard from '../../components/CharacterCard/CharacterCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ApexCharts from 'apexcharts'

var options = {
    series: [{
        data: [
            {
                x: '1 Otc',
                y: [6629.81, 6650.5, 6623.04, 6633.33]
            },
            {
                x: '2 Otc',
                y: [6632.01, 6643.59, 6620, 6630.11]
            },
            {
                x: '3 Otc',
                y: [6630.71, 6648.95, 6623.34, 6635.65]
            },
            {
                x: '4 Otc',
                y: [6635.65, 6651, 6629.67, 6638.24]
            },
            {
                x: '5 Otc',
                y: [6638.24, 6640, 6620, 6624.47]
            },
            {
                x: '6 Otc',
                y: [6624.53, 6636.03, 6621.68, 6624.31]
            },
            {
                x: '7 Otc',
                y: [6624.61, 6632.2, 6617, 6626.02]
            },
            {
                x: '8 Otc',
                y: [6627, 6627.62, 6584.22, 6603.02]
            },
            {
                x: '9 Otc',
                y: [6605, 6608.03, 6598.95, 6604.01]
            },
            // {
            //     x: '10 Otc',
            //     y: [6604.5, 6614.4, 6602.26, 6608.02]
            // },
            // {
            //     x: '11 Otc',
            //     y: [6608.02, 6610.68, 6601.99, 6608.91]
            // },
            // {
            //     x: '12 Otc',
            //     y: [6608.91, 6618.99, 6608.01, 6612]
            // },
            // {
            //     x: '13 Otc',
            //     y: [6612, 6615.13, 6605.09, 6612]
            // },
            // {
            //     x: '14 Otc',
            //     y: [6612, 6624.12, 6608.43, 6622.95]
            // },
            // {
            //     x: '15 Otc',
            //     y: [6623.91, 6623.91, 6615, 6615.67]
            // },
            // {
            //     x: '16 Otc',
            //     y: [6618.69, 6618.74, 6610, 6610.4]
            // },
            // {
            //     x: '17 Otc',
            //     y: [6611, 6622.78, 6610.4, 6614.9]
            // },
            // {
            //     x: '18 Otc',
            //     y: [6614.9, 6626.2, 6613.33, 6623.45]
            // },
            // {
            //     x: '19 Otc',
            //     y: [6623.48, 6627, 6618.38, 6620.35]
            // },
            // {
            //     x: '20 Otc',
            //     y: [6619.43, 6620.35, 6610.05, 6615.53]
            // },
            // {
            //     x: '21 Otc',
            //     y: [6615.53, 6617.93, 6610, 6615.19]
            // },
        ]
    }],
    chart: {
        type: 'candlestick',
        // height: 450,
    },
    title: {
        text: '',
        align: 'left',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
            fontSize:  '27px',
            fontWeight:  'bold',
            fontFamily:  'DM Sans',
            color:  '#ffffff'
        },
    },
    markers: {
        colors: ['#ffffff', '#ffffff', '#ffffff']
    },
    xaxis: {
        type: 'category',
        labels: {
            show: true,
            // rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
                colors: [
                    '#C4C4C4',
                    '#C4C4C4',
                    '#C4C4C4',
                    '#C4C4C4',
                    '#C4C4C4',
                    '#C4C4C4',
                    '#C4C4C4',
                    '#C4C4C4',
                    '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                ],
                fontFamily: 'BarlowMedium',
                fontSize: '10px',
                cssClass: 'apexcharts-xaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            // format: undefined,
            // formatter: undefined,
            // datetimeUTC: true,
            // datetimeFormatter: {
            //     year: 'yyyy',
            //     month: "MMM 'yy",
            //     day: 'dd MMM',
            //     hour: 'HH:mm',
            // },
        },
    },
    yaxis: {
        tooltip: {
            enabled: false
        },
        labels: {
            show: true,
            // rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
                colors: [
                    '#C4C4C4',
                    '#C4C4C4',
                    '#C4C4C4',
                    '#C4C4C4',
                    '#C4C4C4',
                    '#C4C4C4',
                    '#C4C4C4',
                    '#C4C4C4',
                    '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                    // '#C4C4C4',
                ],
                fontFamily: 'BarlowMedium',
                fontSize: '10px',
                cssClass: 'apexcharts-xaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            // format: undefined,
            // formatter: undefined,
            // datetimeUTC: true,
            // datetimeFormatter: {
            //     year: 'yyyy',
            //     month: "MMM 'yy",
            //     day: 'dd MMM',
            //     hour: 'HH:mm',
            // },
        },
    },
    grid: {
        borderColor: 'transparent'
    },
  
    responsive: [{
        breakpoint: undefined,
        options: {},
    }]
  
  };  

const images = [
    'character4',
    'character5',
    'character6',
    'character4',
    'character5',
    'character6',
    'character7',
    'character8',
    'character9',
]

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    swipeToSlide: true,
    slidesToScroll: 5,
    initialSlide: 0,
    arrows: false,
    responsive: [
        {
            breakpoint: 2000,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            }
        },
        {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
        },
        {
            breakpoint: 540,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
        }
    ]
  };

const Home = () => {
    useEffect(() => {
        var chart = new ApexCharts(document.querySelector("#chart"), options);
        chart.render();
    }, []);
    return (
        <div>
            <LeftSide page={'home'} />
            <div className="main-container">
                <div className='homePanel'>
                    {/* <div className='row'> */}
                        <div class="menu-icon dw dw-menu"></div>
                        <div className='landListingPanelContainer'>
                            <div className='landListingPanel'>
                                <span className='title1'>Lands <span className='title2'>Listings</span></span>
                                <div className='arrowIconBox'>
                                    <img className='arrowIconBox__img' src={leftArrowIcon} />
                                    <img className='arrowIconBox__img' src={rightArrowIcon} />
                                </div>
                                <div className='characterCardPanel'>
                                    <Slider {...settings}>
                                        {images.map((item, index) => (
                                            <div key={index} className='characterCardPanel__box'><ChracterCard key={index} imgUrl={item} /></div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>

                            <div className='landListingPanel characterListingPanel'>
                                <span className='title1'>Character Teams <span className='title2'>Listings</span></span>
                                <div className='arrowIconBox'>
                                    <img className='arrowIconBox__img' src={leftArrowIcon} />
                                    <img className='arrowIconBox__img' src={rightArrowIcon} />
                                </div>
                                <div className='characterCardPanel'>
                                    <Slider {...settings}>
                                        {images.map((item, index) => (
                                            <div key={index} className='characterCardPanel__box'><ChracterCard key={index} imgUrl={item} /></div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                        <div className='tradingTrendingPanelContainer'>
                            <div style={{ width: '100%' }}>
                                <div className='tradingViewPanel'>
                                    <div className='tradingViewPanel__box'>
                                        <div className='panel'>
                                            <img src={require('../../assets/images/home/TVL.png').default} />
                                            <div className='box'>
                                                <span className='title1'>TVL</span>
                                                <span className='title2'>$87.754</span>
                                            </div>
                                        </div>
                                        <div className='panel'>
                                            <img src={require('../../assets/images/home/DAILY.png').default} />
                                            <div className='box'>
                                                <span className='title1'>DAILY</span>
                                                <span className='title2'>$78.342</span>
                                            </div>
                                        </div>
                                        <div className='panel'>
                                            <img src={require('../../assets/images/home/APY.png').default} />
                                            <div className='box'>
                                                <span className='title1'>APY</span>
                                                <span className='title2'>+ 12.3 %</span>
                                            </div>
                                        </div>
                                    </div>
                                    <span className='tradingViewPanel__title'>Portfolios performance</span>
                                    <div className='tradingViewPanel__bottomBox'>
                                        <div className='tradingViewPanel__bottomBox__customSelect'>
                                            <select>
                                                <option>BNB</option>
                                                <option>FWC</option>
                                                <option>FWCL</option>
                                                <option>USDT</option>
                                            </select>
                                            <img className='' src={require('../../assets/images/home/trading.png').default} />
                                        </div>
                                        <div className='tradingViewPanel__bottomBox__duringSelect'>
                                            <span className='tradingViewPanel__bottomBox__duringSelect__title'>1D</span>
                                            <span className='tradingViewPanel__bottomBox__duringSelect__title'>1M</span>
                                            <span className='tradingViewPanel__bottomBox__duringSelect__title'>6D</span>
                                            <span className='tradingViewPanel__bottomBox__duringSelect__title'>1Y</span>
                                            <span className='tradingViewPanel__bottomBox__duringSelect__title'>2Y</span>
                                            <span className='tradingViewPanel__bottomBox__duringSelect__title'>
                                                <img style={{ verticalAlign: 'middle', marginTop: '-2px' }} src={require('../../assets/images/home/calendar.png').default} />
                                            </span>
                                        </div>
                                    </div>
                                    <div id="chart"></div>
                                </div>
                            </div>
                            <div style={{ width: '100%' }}>
                                <div className='trendingNFTsPanel'>
                                    <span className='title1'>Trending <span className='title2'>&nbsp;NFTs 🔥</span></span>
                                    <div className='box'>
                                        <div className='boxLeft'><img src={require('../../assets/images/home/trending_img.png').default} /></div>
                                        <div className='boxRight'>
                                            <div className='firstBox'>
                                                <span className='firstBox__title'>Evil Master GOALKEEPER </span>
                                                <span><i className="icon-copy fa fa-heart-o firstBox__icon" aria-hidden="true"></i></span>
                                            </div>
                                            <div className='secondBox'>
                                                <span className='title1'>Owner</span>
                                                <div className='bottomPanel'>
                                                    <img src={require('../../assets/images/home/owner.png').default} />
                                                    <span className='bottomPanel__text'>Redaart1</span>
                                                    <img src={require('../../assets/images/home/yellow_circle.png').default} />
                                                </div>
                                            </div>
                                            <div className='thirdBox'>
                                                <div className='thirdBox__first'>
                                                    <span className='thirdBox__first__title'>Current Bid</span>
                                                    <span className='thirdBox__first__title'>Time</span>
                                                </div>
                                                <div className='thirdBox__second'>
                                                <span className='thirdBox__second__title'>283 USDT</span>
                                                    <span className='thirdBox__second__title'>20H 47M</span>
                                                </div>
                                                <div className='thirdBox__third'>
                                                    <img src={require('../../assets/images/home/owner1.png').default} />
                                                    <img className='thirdBox__third__img' src={require('../../assets/images/home/owner2.png').default} />
                                                    <img className='thirdBox__third__img' src={require('../../assets/images/home/owner3.png').default} />
                                                    <img className='thirdBox__third__img' src={require('../../assets/images/home/owner4.png').default} />
                                                    <img className='thirdBox__third__img' src={require('../../assets/images/home/owner5.png').default} />
                                                </div>
                                            </div>
                                            <div className='fourthBox'>
                                                <span className='fourthBox__title1'>Place Bid</span>
                                                <span className='fourthBox__title2'>View Artboard</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

export default Home;