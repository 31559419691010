import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import ListItems from './pages/list_items/ListItems';
import DetailItem from './pages/detail_item/DetailItem';
import MyOrders from './pages/my_orders/MyOrders';
import MyProfile from './pages/my_profile/MyProfile';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/list_items" element={<ListItems />} />
        <Route exact path="/detail_item" element={<DetailItem />} />
        <Route exact path="/my_orders" element={<MyOrders />} />
        <Route exact path="/my_profile" element={<MyProfile />} />
      </Routes>
    </Router>
  )
}

export default App;