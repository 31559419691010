import React from "react";
import { Link } from 'react-router-dom';
import LogoImg from '../../assets/images/logo.png';
import './LeftSide.scss';
import IconBox from '../IconBox/IconBox';
import LeftButton from "../LeftButton/LeftButton";

const LeftSide = ({ page }) => {
    return (
        <>
            <div className="left-side-bar">
                <div className="brand-logo">
                    <Link to="/" className="logoImg">
                        {/* <img src={LogoImg} alt="" className="dark-logo" /> */}
                        <img src={LogoImg} alt="" className="light-logo" />
                    </Link>
                    <div className="close-sidebar" data-toggle="left-sidebar-close">
                        <i className="ion-close-round"></i>
                    </div>
                </div>
                <div className="iconBoxPanel">
                    <IconBox icon={'home'} title={'Home'} />
                    <IconBox icon={'users'} title={'Leaderboard'} />
                    <IconBox icon={'toolbox'} title={'Inventory'} />
                    <IconBox icon={'store'} title={'Marketplace'} />
                    <IconBox icon={'newspaper'} title={'News'} />
                    <IconBox icon={'headphones'} title={'Support'} />
                </div>
                <div className="menu-block customscroll">
                    <div className="sidebar-menu">
                        <ul id="accordion-menu">
                            {/* <li className="dropdown">
                                <Link to="" className="dropdown-toggle">
                                    <span className="mtext">HOME</span>
                                </Link>
                                <ul className="submenu">
                                    <li><Link to="">DASHBOARD STYLE 1</Link></li>
                                    <li><Link to="">DASHBOARD STYLE 2</Link></li>
                                </ul>
                            </li> */}
                            <li>
                                <Link to="/home" className={`dropdown-toggle no-arrow ${page === 'home' ? 'active' : ''}`}>
                                    <span className="mtext">HOME</span>
                                </Link>
                            </li>
                            <li className="dropdown">
                                <Link to="" className="dropdown-toggle">
                                    <span className="mtext">CHARACTER</span>
                                </Link>
                                <ul className="submenu">
                                    {/* <li><Link to="">LEVEL 1</Link></li> */}
                                    <li className="dropdown">
                                        <Link to="" className="dropdown-toggle">
                                            <span className="mtext">LAND OF ALIEN</span>
                                        </Link>
                                        <ul className="submenu child">
                                            <li><Link to="">HOME</Link></li>
                                            <li><Link to="">HOME</Link></li>
                                            <li><Link to="">HOME</Link></li>
                                            <li><Link to="">HOME</Link></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown">
                                        <Link to="" className="dropdown-toggle">
                                            <span className="mtext">LAND OF ANGEL</span>
                                        </Link>
                                        <ul className="submenu child">
                                            <li><Link to="">HOME</Link></li>
                                            <li><Link to="">HOME</Link></li>
                                            <li><Link to="">HOME</Link></li>
                                            <li><Link to="">HOME</Link></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown">
                                        <Link to="" className="dropdown-toggle">
                                            <span className="mtext">LAND OF EARTH</span>
                                        </Link>
                                        <ul className="submenu child">
                                            <li><Link to="">HOME</Link></li>
                                            <li><Link to="">HOME</Link></li>
                                            <li><Link to="">HOME</Link></li>
                                            <li><Link to="">HOME</Link></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown">
                                        <Link to="" className="dropdown-toggle">
                                            <span className="mtext">LAND OF HELL</span>
                                        </Link>
                                        <ul className="submenu child">
                                            <li><Link to="">HOME</Link></li>
                                            <li><Link to="">HOME</Link></li>
                                            <li><Link to="">HOME</Link></li>
                                            <li><Link to="">HOME</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="leftSideButtonPanel">
                    <Link to="/my_profile"><LeftButton icon={'user'} title={'Profile'} /></Link>
                    <LeftButton icon={'link'} title={'Connected Wallet'} />
                </div>

            </div>
            <div className="mobile-menu-overlay"></div>
        </>
    )
}

export default LeftSide;