import React from 'react';
import './MyProfile.scss';
import LeftSide from '../../components/LeftSide/LeftSide';
import TextField from '../../components/TextField/TextField';
import Description from '../../components/Description/Description';
import ChracterCard from '../../components/CharacterCard/CharacterCard';

const collectionLists = [
    'character4',
    'character5',
    'character6',
    'character4',
    'character5',
    'character6',
    'character7',
    'character8',
    'character9',
]

const MyProfile = () => {
    return (
        <div>
            <LeftSide />
            <div className='main-container'>
                <div className='myProfile'>
                    <div class="menu-icon dw dw-menu"></div>
                    <span className='title'>/ my profile</span>
                    <span className='titleHeader1'>my <span className='titleHeader2'>profile</span></span>
                    <div className='myProfile__box'>
                        <div className='myProfile__box__left'>
                            <div className='myProfile__box__left__img'><img src={require('../../assets/images/profile/profile1.png').default} /></div>
                            <span className='myProfile__box__left__title1'>legendary</span>
                            <span className='myProfile__box__left__title2'>wallet address</span>
                            <span className='myProfile__box__left__title3'>0x815210F...9FcFf70D9</span>
                        </div>
                        <div className='myProfile__box__right'>
                            <span className='myProfile__box__right__title1'>Description</span>
                            <span className='myProfile__box__right__title2'>
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages ....
                            </span>
                            <span className='myProfile__box__right__title3'>Linked Account</span>
                            <div className='myProfile__box__right__iconBox'>
                                <img src={require('../../assets/images/profile/facebook.png').default} />
                                <span className='myProfile__box__right__iconBox__title'>Facebook.com/adonis</span>
                            </div>
                            <div className='myProfile__box__right__iconBox'>
                                <img src={require('../../assets/images/profile/twitter.png').default} />
                                <span className='myProfile__box__right__iconBox__title'>Twitter.com/adonis</span>
                            </div>
                            <div className='myProfile__box__right__iconBox'>
                                <img src={require('../../assets/images/profile/discord.png').default} />
                                <span className='myProfile__box__right__iconBox__title'>Discord.com/adonis</span>
                            </div>
                        </div>
                    </div>
                    <div className='myProfile__tabs'>
                        <div className="tab">
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active text-blue" data-toggle="tab" href="#home" role="tab" aria-selected="true">Edit Profile</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-blue" data-toggle="tab" href="#profile" role="tab" aria-selected="false">Collections</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="home" role="tabpanel">
                                    <div className="mt-30">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div><TextField title={'Facebook username'} /></div>
                                                <div style={{ marginTop: '20px' }}><TextField title={'Twitter username'} /></div>
                                                <div style={{ marginTop: '20px' }}><TextField title={'Discord username'} /></div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='descriptionPanel'>
                                                    <Description
                                                        title={'Description'}
                                                        comment={`It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <span className='updateProfileBtn'>update profile</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="profile" role="tabpanel">
                                    <div className="mt-30">
                                        <div className='cardBox'>
                                            {collectionLists.map((item, index) => (
                                                <div key={index} className='cardBox__panel'><ChracterCard imgUrl={item} /></div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyProfile;